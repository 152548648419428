<script>
import httpClient from "@/services/http.service";

export default {
  name: "AttendanceListWinterScienceSchool",
  data(){
    return{
      attendanceList: []
    }
  },
  methods:{
    async getList() {
      try {
        const {data, status} = await httpClient.get('winter_science_school/winter-visiting-journal/index')
        if (status === 200) {
          this.attendanceList = data
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    await this.getList()
  }
}
</script>

<template>
  <div class="container">
    <h3 class="text-center my-3">Список посещения "ЗИМНАЯ НАУЧНАЯ ШКОЛА: «UIB WINTER SCIENCE SCHOOL 2023»"</h3>
    <div class="my-5" v-if="attendanceList">
      <Accordion :activeIndex="0">
        <AccordionTab v-for="(item, index) in attendanceList" :header="index" :key="index">
          <div class="d-flex justify-content-between mb-2">
            <span class="h5">Список посетивших</span>
            <span class="h5">Количество посетивших: {{ item.length }}</span>
          </div>
          <DataTable :value="item" tableStyle="min-width: 50rem">
            <Column field="fio" header="ФИО"></Column>
            <Column field="email" header="Почта"></Column>
            <Column field="phone" header="Номер телефона"></Column>
            <Column field="time" header="Время скана"></Column>
            <Column field="workplace" header="Место работы"></Column>
          </DataTable>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<style scoped>

</style>